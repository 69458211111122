import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "views/Dashboard";
import NotFound from "views/NotFound";
import Welcome from "views/Welcome";
import LoadingPage from "views/LoadingPage";
/*Dynamic internal imports*/
const Hero = React.lazy(() => import("views/Hero"));
const HowToConsume = React.lazy(() => import("views/HowToConsume"));
const WhyCollaflex = React.lazy(() => import("views/WhyCollaflex"));
const Banners = React.lazy(() => import("views/Banners"));
const Signs = React.lazy(() => import("views/Signs"));
const Products = React.lazy(() => import("views/Products"));
const Questions = React.lazy(() => import("views/Questions"));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Welcome />}></Route>
        <Route
          path="hero"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Hero />
            </React.Suspense>
          }
        />
        <Route
          path="how-to-consume"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <HowToConsume />
            </React.Suspense>
          }
        />
        <Route
          path="why-collaflex"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <WhyCollaflex />
            </React.Suspense>
          }
        />
        <Route
          path="banners"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Banners />
            </React.Suspense>
          }
        />
        <Route
          path="signs"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Signs />
            </React.Suspense>
          }
        />
        <Route
          path="products"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Products />
            </React.Suspense>
          }
        />
        <Route
          path="questions"
          element={
            <React.Suspense fallback={<LoadingPage />}>
              {" "}
              <Questions />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
