/* eslint-disable no-unused-vars */
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import {STORE_NAME} from "constants";

const useStore = create()(
  devtools(
    persist(
      (set, get) => ({
        theme: "light",
        authentication: null,
        activeMenuItem: 0,
        notifications: [],
        isMenuExpanded: false,
        logout: () => set({ authentication: null }),
        toggleTheme: () => {},
        toggleSideBar: () => set((state) => ({ isMenuExpanded: !state.isMenuExpanded })),
        setAuthentication: (data) => set({ authentication: data }),
        setTheme: (theme) => set({ theme: theme }),
        setToggleTheme: (callback) => set({ toggleTheme: callback }),
      }),
      {
        name: STORE_NAME,
        getStorage: () => sessionStorage,
      }
    )
  )
);

export default useStore;
