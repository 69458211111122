/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import { Card } from '@mui/material';
import MenuSideBar from 'components/molecules/MenuSideBar';
import useStore from 'context';

import styles from './SideBar.module.css';

const SideBar = () => {
  const isMenuExpanded = useStore((state) => state.isMenuExpanded);
  const toggleSideBar = useStore((state) => state.toggleSideBar);
  // const setStore = useStore((state) => state.setStore);

  const onToggleSidebar = () => {
    toggleSideBar();
  };

  return (
    <div className={`${styles.container}  ${isMenuExpanded && styles.active}`}>
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          height: '100%',
          paddingTop: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className={styles.toggle}
          onClick={onToggleSidebar}
          onKeyDown={onToggleSidebar}
        ></div>
        <MenuSideBar />
      </Card>
    </div>
  );
};

export default SideBar;
