import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// import Spinner from 'components/atoms/Spinner';
import React from 'react';

const LoadingPage = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'background.default',
      }}
    >
      {/* <Spinner /> */}
      <Stack spacing={2}>
        <Skeleton variant="text" width="20vw" />
        <Skeleton variant="text" width="40vw" height="10vh" />
      </Stack>
    </Box>
  );
};

export default LoadingPage;
