/* eslint-disable no-unused-vars */
import axios from "axios";
import { BASE_URL } from "constants";

axios.defaults.baseURL = BASE_URL;
const Auth = {
  login: async (data) => {
    const response = await axios.post("/authentication/login", data);
    return response.data;
  },
};

export default Auth;
