import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../components/organisms/Header";
import SideBar from "../../components/organisms/SideBar";
import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./styles.module.css";

const Dashboard = () => {
  return (
    <>
      <CssBaseline />
      <div className={styles.container}>
        <SideBar />
        <main className={styles.main}>
          <Header />
          <Container
            maxWidth="lg"
            // className={styles.children}
            sx={{ marginTop: "4vh" }}
          >
            <Outlet />
          </Container>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
