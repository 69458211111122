/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useStore from "../../context";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
// import { useEffect } from 'react';
import { useMutation } from "react-query";
import Auth from "../../services/Auth";
import { useFormik } from "formik";
import styles from "./styles.module.css";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading, data, isError } = useMutation(Auth.login);
  const setAuthentication = useStore((state) => state.setAuthentication);
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      mutate({
        email: values.email,
        password: values.password,
      });
    },
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Ha ocurrido un error, por favor intentelo nuevamente.", {
        variant: "error",
      });
    }
    if (data) {
      enqueueSnackbar("Ingreso exitoso", {
        variant: "success",
      });
      setAuthentication(data);
    }
  }, [isError, data]);

  // const onLogin = async () => {
  //   // const { email, password } = getValues();
  //   mutate({
  //     email: email,
  //     password: password,
  //   });
  // };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "background.default",
      }}
    >
      <Container maxWidth="sm">
        <Card className={styles.paper} variant="outlined">
          <div className={styles.form}>
            <Typography
              variant="h4"
              // component="h2"
              align="center"
            >
              FARMACIAS PERUANAS
            </Typography>
            <form className={styles.inputs}>
              <TextField
                fullWidth
                label="Correo"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                fullWidth
                type="password"
                label="Contraseña"
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />

              <Button
                variant="contained"
                onClick={formik.handleSubmit}
                sx={{ width: "100%", padding: "0.8rem 0" }}
                disabled={isLoading}
              >
                {isLoading ? "Cargando..." : "Ingresar"}
              </Button>
            </form>
          </div>
        </Card>
      </Container>
    </Box>
  );
};

export default Login;
