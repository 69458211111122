/* eslint-disable react-hooks/exhaustive-deps */
/*External Imports*/
import React, { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
/*Internal Imports*/
import useStore from "context";

const ITEM_HEIGHT = 48;
const menuItems = [
  {
    title: "Inicio",
    key: "dashboard",
    href: "/",
    value: 0,
  },
  {
    title: "Productos",
    key: "products",
    href: "/products",
    value: 1,
  },
  {
    title: "Categorías",
    key: "categories",
    href: "/categories",
    value: 2,
  },
  {
    title: "Artículos",
    key: "articles",
    href: "/articles",
    value: 3,
  },
  {
    title: "Banners",
    key: "banners",
    href: "/banners",
    value: 4,
  },
  {
    title: "Suscripciones",
    key: "subscriptions",
    href: "/subscriptions",
    value: 5,
  },
  {
    title: "Por Contactar",
    key: "contacts",
    href: "/contacts",
    value: 6,
  },
];
const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  // const activeMenuItem = useStore((state) => state.activeMenuItem);
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const logout = useStore((state) => state.logout);
  // const setStore = useStore((state) => state.setStore);

  const open = Boolean(anchorEl);

  const handleOptionClick = ({ href, value }) => {
    setActiveMenuItem(value);
    setAnchorEl(null);
    navigate(href);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // change the activeMenuItem depending on the current pathname
    const pathName = location.pathname;
    const item = menuItems.find((item) => {
      // TODO: verify this warning
      const regexStr = `^${item.href}`;
      const regex = new RegExp(regexStr, "g");
      if (item.href !== "/") return regex.test(pathName); // "/" is not considered because wvery pathname starts with "/"
    });
    if (item) setActiveMenuItem(item.value);
  }, []);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {menuItems.map((option) => (
          <MenuItem
            key={option.key}
            selected={option.value === activeMenuItem}
            onClick={() => handleOptionClick({ href: option.href, value: option.value })}
          >
            {option.title}
          </MenuItem>
        ))}
        <MenuItem onClick={handleLogout}>Salir</MenuItem>
      </Menu>
    </div>
  );
};

export default MobileMenu;
