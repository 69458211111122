import MobileMenu from 'components/atoms/MobileMenu';
import Switch from 'components/atoms/Switch';

import styles from './Header.module.css';

const Header = () => {

  return (
    <div className={styles.container}>
      <div className={styles.mobileMenu}>
        <MobileMenu />
      </div>
      {/* {user?.isAuth && <NotificationBadge />} */}
      <Switch />
    </div>
  );
};

export default Header;
