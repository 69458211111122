import React from "react";
import CardMedia from "@mui/material/CardMedia";
import WelcomeImage from "assets/images/welcome_cms.png";
import Box from "@mui/material/Box";

const Welcome = () => {
  return (
    <Box sx={{ height: "80vh", display: "flex", alignItems: "center" }}>
      <CardMedia
        sx={{ borderRadius: "15px" }}
        component="img"
        image={WelcomeImage}
        alt="Welcome to CMS Ninet"
      />
    </Box>
  );
};

export default Welcome;
