import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import useStore from "context";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import useMediaQuery from "./useMediaQuery";

const commonTheme = {
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  shape: {
    borderRadius: 8,
  },
};

const lightTheme = responsiveFontSizes(
  createTheme({
    ...commonTheme,
    palette: {
      mode: "light",
      primary: {
        main: "#005dff",
      },
      secondary: {
        main: "#f50057",
      },
      background: {
        default: "#e7ebf0",
      },
      text: {
        primary: "rgba(10,25,41,0.84)",
        secondary: "rgba(10,25,41,0.54)",
        disabled: "rgba(10,25,41,0.38)",
      },
    },
  })
);

const darkTheme = responsiveFontSizes(
  createTheme({
    ...commonTheme,
    palette: {
      mode: "dark",
      primary: {
        main: "#005dff",
      },
      secondary: {
        main: "#f50057",
      },
      background: {
        default: "#001e3c",
        paper: "#001e3c",
      },
    },
  })
);

function getActiveTheme(themeMode) {
  return themeMode === "light" ? lightTheme : darkTheme;
}

const PREFERENCE_COOKIE_NAME = "theme-preference";

const useTheme = () => {
  const setTheme = useStore((state) => state.setTheme);
  const setToggleTheme = useStore((state) => state.setToggleTheme);

  const userSystemThemePreferenceDark = useMediaQuery("(prefers-color-scheme: dark)");
  const [activeTheme, setActiveTheme] = useState(lightTheme);
  const [cookieTheme, setCookieTheme] = useCookies([PREFERENCE_COOKIE_NAME]);
  const defaultInitialTheme = userSystemThemePreferenceDark ? "dark" : "light";
  const preferredTheme =
    cookieTheme && cookieTheme[PREFERENCE_COOKIE_NAME]
      ? cookieTheme[PREFERENCE_COOKIE_NAME]
      : defaultInitialTheme;
  const [selectedTheme, setSelectedTheme] = useState(preferredTheme);

  const toggleTheme = () => {
    const desiredTheme = selectedTheme === "light" ? "dark" : "light";
    setSelectedTheme(desiredTheme);
    setCookieTheme(PREFERENCE_COOKIE_NAME, desiredTheme);
  };

  useEffect(() => {
    setActiveTheme(getActiveTheme(selectedTheme));
  }, [selectedTheme]);

  useEffect(() => {
    setTheme(cookieTheme["theme-preference"]);
    setToggleTheme(toggleTheme);
  }, [activeTheme]);

  return { activeTheme, cookieTheme, toggleTheme };
};

export default useTheme;
